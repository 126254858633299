import React from "react";
import "./_footer.scss";

const Footer = () => (
  <footer className="footer">
    <a href="privacy-policy.pdf" className="footer__privacy-policy link">
      Privacy Policy
    </a>
    <a href="terms-of-service.pdf" className="footer__terms-of-service link">
      Terms of Service
    </a>
  </footer>
);
export default Footer;
