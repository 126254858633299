import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const logoImg1 = require("./assets/mediate-logo.png");
const logoImg2 = require("./assets/mediate-logo@2x.png");
const logoImg3 = require("./assets/mediate-logo@3x.png");

const useStyles = makeStyles((isWhite: boolean) => ({
  img: (isWhite) => {
    const filter = isWhite ? "brightness(100)" : undefined;
    return {
      maxWidth: "100%",
      filter,
    };
  },
}));
function MediateLogo({ isWhite = false }: { isWhite?: boolean }) {
  const classes = useStyles(isWhite);
  return (
    <img
      className={classes.img}
      style={{ width: "inherit", height: "inherit" }}
      alt="mediate logo"
      src={logoImg1}
      srcSet={`${logoImg1} 200w,
        ${logoImg2} 400w,
        ${logoImg3} 600w`}
      sizes="(max-width: 200px) 200px,
        (max-width: 400px) 400px,
        600px"
    />
  );
}

export default MediateLogo;
