import React, { useState } from "react";
import { Button, CircularProgress, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { requestSignUpWaitlistEmail } from "../firebase/firestore/requests";

const useStyles = makeStyles({
  form: {
    width: "100%",
    maxWidth: 300,
    background: "white",
    padding: "1.5em",
  },
  textInput: {
    margin: "1em auto",
    display: "block",
  },
  submitBtn: {
    height: "3em",
    margin: "2em auto 1em",
    width: "100%",
  },
});
const isValidEmail = (text) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(text.toLowerCase())) {
    return true;
  }
  return false;
};

function SubmitEmailForm({ onClose, onSave }) {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateEmail(email) {
    if (email === "") {
      return `Email is required.`;
    }
    if (!isValidEmail(email)) {
      return `${email} is not a valid email.`;
    }
    return null;
  }

  function handleSubmit() {
    setIsChecked(true);
    const newError = validateEmail(email);
    if (newError === null) {
      setIsLoading(true);
      requestSignUpWaitlistEmail(email)
        .then(() => {
          setIsLoading(false);
          onSave(email);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error);
        });
    } else {
      setError(newError);
    }
  }

  function handleEmailChange(event) {
    const email = event.target.value;
    setEmail(email);
    const newError = validateEmail(email);
    setError(newError);
  }

  return (
    <div className={classes.form}>
      <Typography variant="h5" textAlign="center">
        Join us on our mission to empower journalists
      </Typography>
      <TextField
        fullWidth
        className={classes.textInput}
        defaultValue=""
        label="Enter Email Address"
        onChange={handleEmailChange}
        color="primary"
        error={isChecked && !!error}
        helperText={isChecked && error}
      />
      <Button
        fullWidth
        onClick={handleSubmit}
        className={classes.submitBtn}
        variant="contained"
        color="primary"
        type="submit"
      >
        {isLoading ? (
          <CircularProgress size={30} color="white">
            Loading
          </CircularProgress>
        ) : (
          "OK"
        )}
      </Button>
    </div>
  );
}

export default SubmitEmailForm;
