import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Header from "./header/header";
import LandingPage from "./landing-page/landing-page";
import About from "./about/about";
import Footer from "./footer/footer";
import "./App.scss";
import "./bootstrap.min.css";
import DefaultTheme from "default-theme";

const App = () => (
  <div className="mediate">
    <ThemeProvider theme={DefaultTheme}>
      <Header />
      <LandingPage />
      <About />
      <Footer />
    </ThemeProvider>
  </div>
);

export default App;
