import React from "react";
import "./_about.scss";

const integratesImg1 = require("../assets/integrates-img@1x.png");
const integratesImg2 = require("../assets/integrates-img@2x.png");
const integratesImg3 = require("../assets/integrates-img@3x.png");
const aggregatesImg1 = require("../assets/admin-panel-dashboard.png");
const aggregatesImg2 = require("../assets/admin-panel-dashboard@2x.png");
const aggregatesImg3 = require("../assets/admin-panel-dashboard@3x.png");
const tracksImg1 = require("../assets/tracks-img@1x.png");
const tracksImg2 = require("../assets/tracks-img@2x.png");
const tracksImg3 = require("../assets/tracks-img@3x.png");

const About = () => (
  <div className="about">
    <h2 className="about__title">How It Works</h2>
    <div className="about__subsec one">
      <img
        alt="mediate integrates with authors"
        className="about__subsec__img"
        src={integratesImg1}
        srcSet={`${integratesImg1} 800w,
        ${integratesImg2} 1600w,
        ${integratesImg3} 2400w`}
        sizes="(max-width: 800px) 800px,
        (max-width: 1600px) 1600px,
        2400px"
      />
      <div className="about__subsec__text">
        <h2 className="about__subsec__text__title">You control what feedback to collect</h2>
        <p className="about__subsec__text__desc">
          A feedback widget is embedded at the bottom of each article. Journalists have complete
          control over the rating criteria presented to users.
        </p>
      </div>
    </div>
    <div className="about__subsec two">
      <div className="about__subsec__text">
        <h2 className="about__subsec__text__title">Analyze article trends to find what works</h2>
        <p className="about__subsec__text__desc">
          Article data is aggregated and parsed in real time to provide journalists a clear picture
          of how their articles have performed both through time and relative to one another.
        </p>
      </div>
      <img
        alt="mediate aggregates by journalist"
        className="about__subsec__img"
        src={aggregatesImg1}
        srcSet={`${aggregatesImg1} 800w,
        ${aggregatesImg2} 1600w,
        ${aggregatesImg3} 2400w`}
        sizes="(max-width: 800px) 800px,
        (max-width: 1600px) 1600px,
        2400px"
      />
    </div>
    <div className="about__subsec three">
      <img
        alt="mediate tracks rating history"
        className="about__subsec__img"
        src={tracksImg1}
        srcSet={`${tracksImg1} 800w,
        ${tracksImg2} 1600w,
        ${tracksImg3} 2400w`}
        sizes="(max-width: 800px) 800px,
        (max-width: 1600px) 1600px,
        2400px"
      />
      <div className="about__subsec__text">
        <h2 className="about__subsec__text__title">Connect with your readers!</h2>
        <p className="about__subsec__text__desc">
          Personal journalist profiles are embedded at the top of each article. These profiles are
          completely customizable by the journalist and highlight their past work to users.
        </p>
      </div>
    </div>
  </div>
);

export default About;
