import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

/**
 * keys to connect application to firestore.
 */

export const config =
  process.env.NODE_ENV === "production"
    ? {
        apiKey: "AIzaSyBHo777ca_SllL8b3M2tlEtuPoyWzTTD7s",
        authDomain: "mediate-prod.firebaseapp.com",
        databaseURL: "https://mediate-prod.firebaseio.com",
        projectId: "mediate-prod",
        storageBucket: "mediate-prod.appspot.com",
      }
    : {
        apiKey: "AIzaSyAq4NmstowD2Y9EszSK3RU5lO8x1nAIQC8",
        authDomain: "newsrater.firebaseapp.com",
        databaseURL: "https://newsrater.firebaseio.com",
        projectId: "newsrater",
        storageBucket: "newsrater.appspot.com",
      };
firebase.initializeApp(config);
export default firebase;
export const firebaseAuthProvider = firebase.auth;
export const firebaseAuth = firebase.auth();
export const firestoreDB = firebase.firestore();
export const firebaseFunctions = firebase.functions();
export const firebaseStorage = firebase.storage();
