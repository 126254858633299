import React from "react";
import { Box } from "@material-ui/core";
import EarlyAccessButton from "early-access-btn/early-access-button";
import "./_landing-page.scss";

const headerImg1 = require("../assets/header-background@1x.png");
const headerImg2 = require("../assets/header-background@2x.png");
const headerImg3 = require("../assets/header-background@3x.png");

const stockPhoto1 = require("../assets/landing-page-img@1x.png");
const stockPhoto2 = require("../assets/landing-page-img@2x.png");
const stockPhoto3 = require("../assets/landing-page-img@3x.png");

function LandingPage() {
  return (
    <div className="landing-page">
      <img
        className="landing-page__background-img"
        alt="header img"
        src={headerImg1}
        srcSet={`${headerImg1} 500w,
      ${headerImg2} 1000w,
      ${headerImg3} 1500w`}
        sizes="(max-width: 500px) 500px,
      (max-width: 1000px) 1000px,
      1500px"
      />

      <div className="landing-page__c2a">
        <h1 className="landing-page__c2a__title">An Informed Approach to Journalism</h1>
        <h4 className="landing-page__c2a__desc">
          Understand your readers and track article trends through controlled, opinionated article
          feedback
        </h4>
        <Box className="landing-page__c2a__early-access-container">
          <EarlyAccessButton color="secondary" />
        </Box>
      </div>
      <div className="landing-page__stock-photo">
        <img
          className="landing-page__stock-photo__img"
          alt="example of mediate in action"
          src={stockPhoto1}
          srcSet={`${stockPhoto1} 300w,
        ${stockPhoto2} 600w,
        ${stockPhoto3} 900w`}
          sizes="(max-width: 300px) 300px,
        (max-width: 600px) 600px,
        900px"
        />
      </div>
    </div>
  );
}

export default LandingPage;
