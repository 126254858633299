import React from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import SubmitEmailForm from "./submit-email-form";
import "./early-access-button.scss";
type EarlyAccessButtonProps = {
  color?: "primary" | "secondary";
};
function EarlyAccessButton({ color = "secondary" }: EarlyAccessButtonProps) {
  const [isShowEmailForm, setShowEmailForm] = React.useState(false);

  return (
    <Box className="button-container">
      <Button
        variant="contained"
        color={color}
        className={`early-access-button --${color}`}
        onClick={() => setShowEmailForm(true)}
      >
        <Typography
          className="early-access-button__text"
          variant="body1"
          style={{ letterSpacing: "0.01em" }}
        >
          Get Early Access
        </Typography>
      </Button>
      <Dialog
        style={{ padding: "auto 1em" }}
        open={isShowEmailForm}
        onClose={() => setShowEmailForm(false)}
      >
        <DialogContent>
          <SubmitEmailForm
            onClose={() => setShowEmailForm(false)}
            onSave={() => setShowEmailForm(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EarlyAccessButton;
