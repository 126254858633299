import React, { ReactElement } from "react";
import { AppBar, Box, Button, Typography, useScrollTrigger } from "@material-ui/core";
import MediateLogo from "mediate-logo";
import EarlyAccessButton from "../early-access-btn/early-access-button";
import "./_header.scss";

type ElevationScrollProps = {
  children: ReactElement;
};
function ElevationScroll({ children }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 15,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 5 : 0,
    color: trigger ? "primary" : "transparent",
  });
}
function Header() {
  const handleHeaderClick = () => window.scrollTo(0, 510);

  return (
    <ElevationScroll>
      <AppBar className="header" position="sticky">
        <Box display="flex" alignItems="center" justifyContent="space-around">
          <a className="header__logo" href="/home">
            <MediateLogo isWhite={true} />
          </a>
          <Box display="flex" className={"header__section-links"}>
            <Button
              style={{ textTransform: "none" }}
              onClick={() => handleHeaderClick()}
              className="header__section-link"
            >
              <Typography variant="h5" style={{ color: "white", fontWeight: 500 }}>
                How It Works
              </Typography>
            </Button>
            <Box width="fit-content" margin="auto 40px">
              <EarlyAccessButton color="secondary" />
            </Box>
          </Box>{" "}
        </Box>
      </AppBar>
    </ElevationScroll>
  );
}

export default Header;
