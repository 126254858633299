import { firebaseFunctions } from "../config";

export async function requestSignUpWaitlistEmail(email: string) {
  try {
    const SIGN_UP_JOURNALISTS_FN = "requestSignUpWaitlistEmail";
    const resp = await firebaseFunctions.httpsCallable(SIGN_UP_JOURNALISTS_FN)({
      email,
    });
    return resp;
  } catch (error) {
    console.log("error: ", error);
  }
}
